<template>
    <div>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <div v-show="!importColumnMatched && !importOngoing && !importDone">
            <b-row class="mt-2 mx-1">
                <b-col sm="12">
                    <!-- Asset Form -->
                    <b-card title="Upload Form">
                        <b-row class="mt-2 mb-4 ml-1" no-gutters>
                            <b-col sm="10" md="8" lg="6" class="mr-4">
                                <b-form-group label="Asset Form"
                                    description="Please select a valid json or csv file for asset import form format.">
                                    <b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
                                        :state="Boolean(file)" placeholder="Choose a JSON or CSV file" />
                                </b-form-group>
                                <div>
                                    Download JSON template
                                    <a :href="jsonTemplateUrl" download="AssetImporter.json">
                                        here
                                    </a>
                                    and CSV template
                                    <a :href="csvTemplateUrl" download="AssetImporter.csv">
                                        here.
                                    </a>
                                </div>
                            </b-col>
                            <b-col sm="1">
                                <b-button variant="primary" class="reset-button" @click="startAnotherImport()">
                                    Reset
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- Asset Form Summary -->
                    <b-card v-show="importPreprocess" title="Asset Form Summary"
                        sub-title="Summary details of processed asset form">
                        <b-form-group label="Total Assets:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            class="mt-4" description="*Total count of rows processed.">
                            <b>{{ totalAssets ? totalAssets.toLocaleString() : 0 }}</b>
                        </b-form-group>

                        <b-form-group label="Existing Assets:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            description="*Only existing assets will be updated for this import.">
                            <b>{{ existingAssets ? existingAssets.toLocaleString() : 0 }}</b>
                        </b-form-group>

                        <b-form-group label="Non-Existent Assets:" label-cols-sm="2" label-class="pt-0"
                            label-align-sm="right" description="*Non-existent assets won't be updated for this import.">
                            <b>{{ nonExistentAssets ? nonExistentAssets.toLocaleString() : 0 }}</b>
                        </b-form-group>
                    </b-card>

                    <!-- Matched Asset Details -->
                    <b-card v-show="importPreprocess && existingAssets > 0" title="Match Asset Details"
                        sub-title="Match the assets details you want to update with the details from your imported file.">
                        <b-row class="mt-4">
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Asset Code" label-cols-sm="3" label-class="pt-2"
                                    label-align-sm="right">
                                    <b-form-input type="text" v-model="selected.assetCode" placeholder readonly />
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Name" label-cols-sm="3" label-class="pt-2" label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(name) => name.value" v-model="selected.name">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Serial No" label-cols-sm="3" label-class="pt-2" label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(serialNumber) => serialNumber.value" v-model="selected.serialNo">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Condition" label-cols-sm="3" label-class="pt-2" label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(condition) => condition.value" v-model="selected.condition">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Category" label-cols-sm="3" label-class="pt-2" label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(category) => category.value" v-model="selected.category">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Description" label-cols-sm="3" label-class="pt-2"
                                    label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(description) => description.value" v-model="selected.description">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Purchase Date" label-cols-sm="3" label-class="pt-2"
                                    label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(purchaseDate) => purchaseDate.value" v-model="selected.purchaseDate">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Expiration Date" label-cols-sm="3" label-class="pt-2"
                                    label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(expirationDate) => expirationDate.value"
                                        v-model="selected.expirationDate">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="10" md="10" lg="8" class="ml-1">
                                <b-form-group label="Unit Cost" label-cols-sm="3" label-class="pt-2" label-align-sm="right">
                                    <v-select class="style-chooser" label="text" :options="importedColumns"
                                        :reduce="(unitCost) => unitCost.value" v-model="selected.unitCost">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- Notication -->
                    <b-card title="Warning" v-show="importWarning.length > 0">
                        <b-card-text>
                            <i class="icon-info"></i> &nbsp;{{ importWarning }}
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mb-4 mx-1" v-show="importPreprocess">
                <b-col sm="12" class="text-sm-right">
                    <b-button v-if="existingAssets === 0" variant="primary" @click="startAnotherImport()">
                        Start Another Import
                    </b-button>
                    <b-button v-else variant="primary" @click="onColumnMatching()">
                        Next
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <div v-show="importColumnMatched && !importOngoing && !importDone">
            <b-row class="mt-2 ml-1">
                <b-col sm="12">
                    <!-- Fields to Update -->
                    <b-card title="Fields to Update">
                        <b-form-group label="Asset Code:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.assetCode)" class="mt-4">
                            <b>{{ selected.assetCode }}</b>
                        </b-form-group>
                        <b-form-group label="Name:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.name)" class="mt-2">
                            <b>{{ selected.name }}</b>
                        </b-form-group>
                        <b-form-group label="Serial No:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.serialNo)" class="mt-2">
                            <b>{{ selected.serialNo }}</b>
                        </b-form-group>
                        <b-form-group label="Condition:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.condition)" class="mt-2">
                            <b>{{ selected.condition }}</b>
                        </b-form-group>
                        <b-form-group label="Description:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.description)" class="mt-2">
                            <b>{{ selected.description }}</b>
                        </b-form-group>
                        <b-form-group label="Purchase Date:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.purchaseDate)" class="mt-2">
                            <b>{{ selected.purchaseDate }}</b>
                        </b-form-group>
                        <b-form-group label="Expiration Date:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.expirationDate)" class="mt-2">
                            <b>{{ selected.expirationDate }}</b>
                        </b-form-group>
                        <b-form-group label="Unit Cost:" label-cols-sm="2" label-class="pt-0" label-align-sm="right"
                            v-if="!isDefaultValue(selected.unitCost)" class="mt-2">
                            <b>{{ selected.unitCost }}</b>
                        </b-form-group>
                    </b-card>

                    <!-- Content Preview -->
                    <b-card title="Content Preview"
                        sub-title="Below is a list of the asset details that will be updated once the import proceeds.">
                        <b-row class="mt-2">
                            <b-col sm="6" md="4" offset-md="8" class="text-md-right">
                                <b-input-group prepend="Show" append="/ Page">
                                    <b-form-select :options="pageOptions" v-model="perPage" />
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col sm="12">
                                <b-table show-empty striped hover :items="items" :fields="fields"
                                    :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" sm="12" class="my-1">
                                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                            </b-col>
                            <b-col md="4" sm="12" class="my-1">
                                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
                                    v-model="currentPage" class="my-0" />
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <b-row class="mb-4 mx-1">
                <b-col sm="12" class="text-sm-right">
                    <b-button variant="secondary" @click="backToUploadForm()">
                        Back
                    </b-button>
                    &nbsp;
                    <b-button variant="primary" @click="onImportData()">
                        Import
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <div v-show="importOngoing || importDone">
            <b-row class="my-12">
                <b-col sm="12">
                    <b-card title="Import Status" sub-title>
                        <div v-if="importErrors.length > 0">
                            <p class="card-text">{{ importResultLog }}</p>
                            <ul>
                                <li v-for="(item, index) in importErrors" :key="index">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>

                        <div v-else>
                            <p class="card-text my-4">
                                {{ importStatusDisplay }}
                            </p>
                        </div>

                        <span v-show="!importOngoing">
                            <b-button variant="primary" @click="startAnotherImport()">
                                Start Another Import
                            </b-button>
                        </span>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ImportUtil } from '@/utils/importUtil';

// API & DAO
import assetDAO from '@/database/assets';
import assetApi from '@/api/assetApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/env-constants';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
    name: 'existing-asset-importer',
    components: {
        Loading
    },
    data() {
        return {
            file: null,
            jsonData: null,
            processedJsonData: null,

            importPreprocess: false,
            importColumnMatched: false,
            importOngoing: false,
            importDone: false,

            importWarning: '',
            hasError: false,
            importResultLog: '',
            importErrors: [],

            jsonTemplateUrl: '../../assets/files/AssetImporter.json',
            csvTemplateUrl: '../../assets/files/AssetImporter.csv',

            allAssetTypesObj: {},
            allCompaniesObj: {},
            existingAssetsObj: {},

            currUserId: this.$store.getters.loggedUser.id,
            isLoading: false,

            totalAssets: 0,
            existingAssets: 0,
            nonExistentAssets: 0,
            importedColumns: [],

            selected: {
                assetCode: 'assetCode',
                name: config.dropdownDefaultValue,
                serialNo: config.dropdownDefaultValue,
                condition: config.dropdownDefaultValue,
                category: config.dropdownDefaultValue,
                description: config.dropdownDefaultValue,
                purchaseDate: config.dropdownDefaultValue,
                expirationDate: config.dropdownDefaultValue,
                unitCost: config.dropdownDefaultValue,
            },


            //--------------------------------------------
            items: [],
            fields: [],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    computed: {
        importStatusDisplay() {
            let statusDisplay = '';

            if (this.importOngoing) {
                statusDisplay = 'Import In-Progress.';
            } else if (this.hasError) {
                statusDisplay = this.importResultLog;
            } else {
                statusDisplay = 'Import Successful! \n' + this.importResultLog;
            }
            return statusDisplay;
        },
        serialNoRegex() {
            return config.serialNoRegex;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
    },
    mounted() {
        setTimeout(() => {
            try {
                // show loading indicator
                this.isLoading = true;

                this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
                this.allCompaniesObj = { ...this.$store.getters.companies };
            } catch (_error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
				// hide loading indicator
				this.isLoading = false;
			}
        }, config.timeout);
    },
    methods: {
        isDefaultValue(value) {
            return value === config.dropdownDefaultValue || value === null;
        },

        startAnotherImport() {
            // reset import status
            this.importPreprocess = false;
            this.importColumnMatched = false;
            this.importOngoing = false;
            this.importDone = false;

            // reset error status
            this.resetErrorStatus();

            this.selected = {
                assetCode: 'assetCode',
                name: config.dropdownDefaultValue,
                serialNo: config.dropdownDefaultValue,
                condition: config.dropdownDefaultValue,
                category: config.dropdownDefaultValue,
                description: config.dropdownDefaultValue,
                purchaseDate: config.dropdownDefaultValue,
                expirationDate: config.dropdownDefaultValue,
                unitCost: config.dropdownDefaultValue,
            };
            this.fields = [];

            // reset form fields
            this.$refs.fileinput.reset();
            this.file = null;
            this.jsonData = null;
            this.processedJsonData = null;
        },
        resetErrorStatus() {
            this.importWarning = '';
            this.hasError = false;
            this.importResultLog = '';
            this.importErrors = [];
        },
        backToUploadForm() {
            this.importColumnMatched = false;
        },

        onUploadForm(ev) {
            const file = ev.target.files[0];

            if (this.validateUploadForm(file)) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    let extension = file.name.split('.').pop().toLowerCase();
                    if (extension === 'csv') {
                        const { data } = Papa.parse(e.target.result, {
                            header: true,
                            skipEmptyLines: true,
                            dynamicTyping: true,
                            encoding: "UTF-8",
                        });

                        this.jsonData = {
                            assets: data
                        };
                    } else {
                        this.jsonData = JSON.parse(e.target.result);
                    }

                    this.importPreprocess = true;
                    await this.onPreProcessAssets();
                };

                reader.readAsText(file);
            } else {
                this.importPreprocess = false;
            }
        },
        validateUploadForm(file) {
            let isValid = true;
            const fileTypes = ['csv', 'json'];
            const extension = file.name.split('.').pop().toLowerCase();
            const isAllowed = fileTypes.indexOf(extension) > -1;

            if (!file) {
                this.$toaster.warning(
                    'Please select a valid Asset Import Form to proceed.'
                );
                isValid = false;
                this.jsonData = null;
            } else if (!isAllowed) {
                this.$toaster.error(
                    'Invalid File Type: Please import a valid Asset Import Form in JSON or CSV format.'
                );
                isValid = false;
                this.jsonData = null;
            }

            return isValid;
        },
        async onPreProcessAssets() {
            // show loading indicator
            this.isLoading = true;

            // reset
            this.totalAssets = 0
            this.existingAssets = 0;
            this.nonExistentAssets = 0;

            // reset error status
            this.resetErrorStatus();

            let assets = this.jsonData.assets;
            this.totalAssets = _.size(assets);

            if (this.totalAssets > 0) {

                // init column matching reference
                let importColumns = Object.keys(assets[0]);
                this.importedColumns = DropDownItemsUtil.retrieveItems(importColumns);

                if (!assets[0].assetCode) {
                    this.importWarning = '"assetCode" column is required in import file for asset id reference to proceed.';
                    this.$toaster.warning(this.importWarning);
                    this.isLoading = false;
                    return;
                }

                let assetCodes = _.map(assets, 'assetCode');
                let assetsObjResult = await assetDAO.getAssetsByCode(assetCodes);
                this.existingAssetsObj = assetsObjResult[0];

                _.forEach(assets, asset => {
                    let assetCode = asset.assetCode;
                    if (!_.isEmpty(this.existingAssetsObj[assetCode])) {
                        this.existingAssets++;
                    } else {
                        this.nonExistentAssets++;
                    }
                });

                if (_.isEmpty(this.existingAssetsObj)) {
                    this.importWarning = 'No eligible assets to be updated.';
                    this.$toaster.warning(this.importWarning);
                    this.isLoading = false;
                    return;
                }
            }

            // hide loading indicator
            this.isLoading = false;
        },

        onColumnMatching() {
            // show loading indicator
            this.isLoading = true;

            if (this.validateColumnMatching()) {
                this.importColumnMatched = true;
                this.processedJsonData = this.formatJsonData(this.jsonData.assets);

                // Update Content View
                this.items = this.processedJsonData.assets;
                this.totalRows = _.size(this.items);
            }

            // hide loading indicator
            this.isLoading = false;
        },
        getAssetDetailFields(selected) {
            let fields = [];
            _.forEach(selected, (value, key) => {
                if (value !== config.dropdownDefaultValue && value !== null) {
                    fields.push(key);
                }
            });
            return fields;
        },
        validateColumnMatching() {
            if (this.selected.assetCode === config.dropdownDefaultValue) {
                this.$toaster.warning("Matching Asset Code column is required for import reference.");
                return false;
            }

            this.fields = this.getAssetDetailFields(this.selected);
            if (_.size(this.fields) < 2) {
                this.$toaster.warning("At least two column matches must be selected.");
                return false;
            }

            let importColumns = _.filter(this.selected, o => {
                return o !== config.dropdownDefaultValue && o !== null;
            });

            if (ImportUtil.hasDuplicates(importColumns)) {
                this.$toaster.warning("Column matched should be unique per asset detail.");
                return false;
            }

            return true;
        },
        formatJsonData(params) {
            let results = [];

            params.forEach(param => {
                param = ImportUtil.trimWhiteSpaces(param);

                let asset = {};
                asset.assetCode = param[this.selected.assetCode];

                if (!_.isEmpty(this.existingAssetsObj[asset.assetCode])) {

                    // Match Columns
                    if (this.selected.name !== config.dropdownDefaultValue) {
                        asset.name = param[this.selected.name];
                    }
                    if (this.selected.serialNo !== config.dropdownDefaultValue) {
                        asset.serialNo = param[this.selected.serialNo];
                    }
                    if (this.selected.condition !== config.dropdownDefaultValue) {
                        asset.condition = param[this.selected.condition];
                    }
                    if (this.selected.category !== config.dropdownDefaultValue) {
                        asset.category = param[this.selected.category];
                    }
                    if (this.selected.description !== config.dropdownDefaultValue) {
                        asset.description = param[this.selected.description];
                    }
                    if (this.selected.purchaseDate !== config.dropdownDefaultValue) {
                        asset.purchaseDate = param[this.selected.purchaseDate];
                    }
                    if (this.selected.expirationDate !== config.dropdownDefaultValue) {
                        asset.expirationDate = param[this.selected.expirationDate];
                    }
                    if (this.selected.unitCost !== config.dropdownDefaultValue) {
                        asset.unitCost = param[this.selected.unitCost];
                    }

                    // Add Extra Fields
                    if (asset.purchaseDate) {
                        asset.purchaseDateTimeStamp = DateUtil.startDateTimeStamp(asset.purchaseDate);
                    }
                    if (asset.expirationDate) {
                        asset.expirationDateTimeStamp = DateUtil.startDateTimeStamp(asset.expirationDate);
                    }
                    if (asset.unitCost) {
                        asset.unitCost = parseFloat(asset.unitCost);
                    }

                    results.push(asset);
                }
            });

            return {
                assets: results,
            };
        },

        async onImportData() {
            let assetsArr = this.processedJsonData.assets;
            if (assetsArr.length === 0) {
                this.$toaster.warning('At least 1 asset is required per import.');
                return;
            } else if (assetsArr.length > 10000) {
                this.$toaster.warning(
                    'Only maximum of 10,000 assets is allowed per import.'
                );
                return;
            } else if (ImportUtil.hasDuplicates(assetsArr, 'assetCode')) {
                let duplicates = ImportUtil.getDuplicates(assetsArr, 'assetCode');
                this.$toaster.warning(
                    'Duplicates detected! Please address the following asset(s): ' +
                    JSON.stringify(duplicates)
                );
                return;
            } else if (ImportUtil.hasBlankColumnName(assetsArr[0])) {
                this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
                return;
            } else if (!this.validateDataImport(assetsArr)) {
                return;
            }

            await this.saveOnDatabase();
        },
        validateDataImport(assetsArr) {
            let vm = this;
            let isValid = true;
            let errors = [];

            let allowedCompanyIds = _.map(this.allCompaniesObj, 'id');

            _.forEach(assetsArr, (item, key) => {
                let assetCode = item.assetCode;
                let index = key + 1;
                let errPrefix = (!assetCode || assetCode.length === 0) ? 'Item ' + index : 'Asset ' + assetCode;

                let assetObj = vm.existingAssetsObj[assetCode];
                let assetTypeId = assetObj.assetTypeId;
                let assetTypeObj = vm.allAssetTypesObj[assetTypeId];

                if (!assetTypeObj || (assetTypeObj.originId && !allowedCompanyIds.includes(assetTypeObj.originId))) {
                    errors.push(errPrefix + ' has an asset type, "' + assetObj.assetType + '", that you don\'t have access to.');
                } else {
                    if (!vm.isDefaultValue(vm.selected.condition) && !ImportUtil.isValidCondition(assetTypeObj, item.condition)) {
                        let errMsg = errPrefix + ' has an invalid condition ' + item.condition + '. Valid conditions are: ';
                        errMsg += _.map(assetTypeObj.conditions, 'condition').join();
                        errors.push(errMsg);
                    }
                    if (!vm.isDefaultValue(vm.selected.category) && assetTypeObj.hasCategories && !ImportUtil.isValidCategory(assetTypeObj, item.category)) {
                        let errMsg = errPrefix + ' has an invalid category ' + item.category + '. Valid categories are: ';
                        errMsg += _.map(assetTypeObj.categories, 'name').join();
                        errors.push(errMsg);
                    }
                }

                // Serial No
                if (item.serialNo && item.serialNo.length > 0 && !this.serialNoRegex.test(item.serialNo)) {
                    errors.push(errPrefix + ' serial no. should only contain uppercase letters and numbers without white spaces.');
                }

                // Description
                if (item.description && item.description.length > 0 && !this.remarksRegex.test(item.description)) {
                    errors.push(errPrefix + ' description should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
                }

                if (!vm.isDefaultValue(vm.selected.purchaseDate)) {
                    if (!ImportUtil.isValidDate(item.purchaseDate)) {
                        errors.push(errPrefix + ' purchase date, ' + item.purchaseDate + ', should follow the MM-DD-YYYY or MM/DD/YYYY format.');
                    } else {
                        let purchaseDateTimeStamp = DateUtil.getTimestamp(item.purchaseDate);
                        if (purchaseDateTimeStamp > DateUtil.getCurrentTimestamp()) {
                            errors.push(errPrefix + ' purchase date, ' + item.purchaseDate + ' is invalid. Future date is not allowed.');
                        }
                    }
                }
                if (!vm.isDefaultValue(vm.selected.expirationDate) && !ImportUtil.isValidDate(item.expirationDate)) {
                    errors.push(errPrefix + ' expiration date, ' + item.expirationDate + ', should follow the MM-DD-YYYY or MM/DD/YYYY format.');
                }
                if (!vm.isDefaultValue(vm.selected.unitCost) && !ImportUtil.isValidNumber(item.unitCost)) {
                    errors.push(errPrefix + ' unit cost, ' + item.unitCost + ' should be a number.');
                }
            });

            if (errors.length > 0) {
                isValid = false;

                this.hasError = true;
                this.importErrors = errors;
                this.importResultLog = 'The imported form has error(s).';
                this.importDone = true;
                this.importOngoing = false;
            }

            return isValid;
        },
        async saveOnDatabase() {
            try {
                // show loading indicator
                this.isLoading = true;

                this.importOngoing = true;
                this.importDone = false;

                let param = {
                    currUserId: this.currUserId,
                    currTimeStamp: DateUtil.getCurrentTimestamp(),
                    assets: this.getAssetsObjToUpdate()
                };

                let { data } = await assetApi.updateAssets(param);

                this.hasError = !data.isSuccess;
                this.importErrors = data.errors;
                this.importResultLog = data.message;
                this.importDone = true;
                this.importOngoing = false;

                // update store
                let assetsObj = data.assets;
                this.$store.dispatch('updateAllAssets', assetsObj);
            } catch (error) {
                this.hasError = true;
                this.importResultLog = error;
                this.importDone = true;
                this.importOngoing = false;
            } finally {
				// hide loading indicator
				this.isLoading = false;
			}
        },
        getAssetsObjToUpdate() {
            let assetsObj = { ...this.existingAssetsObj };

            _.forEach(this.processedJsonData.assets, item => {
                let assetCode = item.assetCode;
                let assetObj = assetsObj[assetCode];


                assetObj.details = Object.assign(assetObj.details, item);

                if (!this.isDefaultValue(this.selected.name)) {
                    assetObj.details.name = item.name;
                }
                if (!this.isDefaultValue(this.selected.serialNo)) {
                    assetObj.details.serialNo = item.serialNo;
                }
                if (!this.isDefaultValue(this.selected.condition)) {
                    assetObj.details.condition = item.condition;
                }
                if (!this.isDefaultValue(this.selected.category)) {
                    assetObj.details.category = item.category;
                }
                if (!this.isDefaultValue(this.selected.description)) {
                    assetObj.details.description = item.description;
                }
                if (!this.isDefaultValue(this.selected.purchaseDate)) {
                    assetObj.details.purchaseDate = item.purchaseDateTimeStamp;
                }
                if (!this.isDefaultValue(this.selected.expirationDate)) {
                    assetObj.details.expirationDate = item.expirationDateTimeStamp;
                }
                if (!this.isDefaultValue(this.selected.unitCost)) {
                    assetObj.details.unitCost = parseFloat(item.unitCost);
                }
            });

            return assetsObj;
        }
    }
}
</script>